import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { StaticImage } from 'gatsby-plugin-image'

import { Layout } from '../components/LayoutLanding'
import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { DownArrow } from 'styled-icons/boxicons-regular'
import { SEO } from '../components/SEO'

const Features = ({ location }) => (
  <Layout>
    <SEO
      title="Vanna | Funcionalidades"
      tags={[
        'Infraestrutura',
        'Split',
        'Pagamentos instanâneos',
        'Pix',
        'Receba em segundos',
      ]}
      canonical="https://vanna.app/funcionalidades/"
    />
    <LandingTopbar />
    <Links />
    <section className="my-16 px-5 md:px-8 lg:px-16">
      <div className="md:max-w-3xl md:mx-auto md:text-center">
        <h1 className="text-gray-900 text-3xl font-heading font-medium lg:text-4xl">
          Recebimentos Pix com Split, sem burocracia.
        </h1>
        <p className="my-4 text-gray-800 leading-6 sm:text-xl lg:text-lg xl:text-xl">
          Mude a forma de fazer cobranças e receba e divida valores de forma
          instantânea.
          <br className="hidden md:block" /> Seu dinheiro na conta, pronto para
          te ajudar a crescer e conquistar o mercado.
        </p>
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="/images/logo-s.png" alt="Logo" />
        </div>
        <h2 className="text-gray-900 text-center text-2xl font-heading font-medium lg:text-3xl mt-16">
          Veja abaixo nossas funcionalidades
        </h2>
        <a href="#features" className="flex justify-center">
          <DownArrow className="bouncing" size={30} />
        </a>
      </div>
    </section>
    <section id="features" className="p-12 bg-yellow-light">
      <div className="flex flex-col-reverse md:flex-row items-center justify-center">
        <div className="w-full">
          <div className="py-6 md:block block max-w-3xl mx-auto md:px-20 px-5 ">
            <h2 className="text-gray-900 text-1xl font-heading font-medium lg:text-2xl my-4">
              Cobranças em no máximo 35 segundos
            </h2>
            <p className="py-2">
              Nosso fluxo de registro de dados otimizado permite mais
              recebimentos em menos tempo. Faça o teste você mesmo e nos diga o
              que acha!
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full  xl:w-3/4">
            <StaticImage
              src="../images/cob.png"
              placeholder="tracedSVG"
              layout="fullWidth"
              quality={100}
              alt="Tarifas"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="p-12">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="w-full flex justify-center">
          <div className="w-full xl:w-3/4">
            <StaticImage
              src="../images/rec.png"
              placeholder="tracedSVG"
              layout="fullWidth"
              quality={100}
              alt="Recebimentos com split"
            />
          </div>
        </div>
        <div className="w-full">
          <div className="py-6 md:block block md:px-20 px-5 max-w-3xl mx-auto">
            <h2 className="text-gray-900 text-1xl font-heading font-medium lg:text-2xl my-4">
              Recebimentos com split!
            </h2>
            <p className="py-2">
              Nossa infraestrutura de recebimento permite regras para split que
              automatizam e facilitam o seu negócio. Ao receber valores as
              partes já são divididas entre os dependentes registrados. Simples
              e rápido.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="p-12 bg-yellow-light">
      <div className="flex flex-col-reverse md:flex-row items-center justify-center">
        <div className="w-full">
          <div className="py-6 md:block block md:px-20 px-5 max-w-3xl mx-auto">
            <h2 className="text-gray-900 text-1xl font-heading font-medium lg:text-2xl my-4">
              Elimine perdas
            </h2>
            <p className="py-2">
              Nosso sistema sempre lembra seu cliente a pagar no prazo. Com
              nossos sistema de webhooks você pode cancelar o acesso de clientes
              inadimplentes automaticamente.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full xl:w-3/4">
            <StaticImage
              src="../images/los.png"
              placeholder="tracedSVG"
              layout="fullWidth"
              quality={100}
              alt="Elimine perdas"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="p-12">
      <div className="flex flex-col md:flex-row items-center justify-center">
        <div className="w-full flex justify-center">
          <div className="w-full xl:w-3/4">
            <StaticImage
              src="../images/ris.png"
              placeholder="tracedSVG"
              layout="fullWidth"
              quality={100}
              alt="Reduza riscos"
            />
          </div>
        </div>
        <div className="w-full">
          <div className="py-6 md:block block md:px-20 px-5 max-w-3xl mx-auto">
            <h2 className="text-gray-900 text-1xl font-heading font-medium lg:text-2xl my-4">
              Reduza riscos
            </h2>
            <p className="py-2">
              Com velocidade e automação a plataforma Vanna permite que seu
              negócio cresça rápido com um custo operacional baixo e sem riscos
              devido as cobranças instantâneas via Pix.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="p-12 bg-yellow-light">
      <div className="flex flex-col-reverse md:flex-row items-center justify-center">
        <div className="w-full">
          <div className="py-6 md:block block md:px-20 px-5 max-w-3xl mx-auto">
            <h2 className="text-gray-900 text-1xl font-heading font-medium lg:text-2xl my-4">
              Dashboard simples e direto ao ponto
            </h2>
            <p className="py-2">
              Analise as principais informações de seu negócio em um piscar de
              olhos e tenha atalhos rápidos para gerar novas cobranças simples
              ou recorrentes.
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full xl:w-3/4">
            <StaticImage
              src="../images/obj.png"
              placeholder="tracedSVG"
              layout="fullWidth"
              quality={100}
              alt="Dashboard simples e direto ao ponto"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="p-12 bg-yellow-light">
      <div className="my-10 grid gap-y-6 md:max-w-5xl md:mx-auto md:grid-cols-2 md:gap-x-8">
        <div className="hidden md:block overflow-hidden px-6 py-8 shadow-lg rounded-lg lg:px-12 lg:pt-20 lg:pb-24 bg-white">
          <div>
            <h3 className="font-semibold leading-6 text-lg">
              Não me convenceu
            </h3>
            <p className="mt-3 text-gray-800">
              Tudo bem, sem problemas, entendemos que talvez não seja a sua hora
              de utilizar as cobranças instantâneas em seu negócio.
            </p>
            <p className="mt-3 text-gray-800">
              Caso queira mais informações não hesite em entrar em contato com a
              gente pelo chat. Somos 100% apaixonados pelo assunto e podemos
              conversar por horas e horas.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden px-6 py-8 shadow-lg rounded-lg lg:px-12 lg:pt-20 lg:pb-16 bg-white">
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold leading-6">
              Quero começar agora
            </h3>
            <p className="mt-3 text-gray-800">
              Muito legal! Você só precisa clicar no botão abaixo e se
              cadastrar! Após o cadastro pedimos algumas infomações para liberar
              sua conta.
            </p>
            <p className="mt-3 text-gray-800">
              Essa liberação pode demorar de alguns minutos a horas, fique
              atento ao seu email para as atualizações de status.
            </p>
            <div className="rounded-md shadow-md mt-8">
              <OutboundLink
                target="_blank"
                rel="noopener"
                href="https://api.whatsapp.com/send/?phone=5515981779550&text=Fala+Guilherme%21+Estou+interessado+na+Vanna%2C+meu+nome+%C3%A9&app_absent=0"
                className="bg-yellow-brand w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-purple-brand md:py-2 md:text-lg md:px-6"
              >
                Quero saber mais
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Features
